.final_check_container{
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    box-shadow: 0px 0px 5px rgb(139, 137, 137);
}
.final_check_container_left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}
.final_review_description p{
overflow: auto;
}
.final_review_description p::-webkit-scrollbar{
    width: 150px;
    color: black;
}
.final_check_container_right{
    padding: 0rem 2rem;
}
.final_check_oneline{
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
}
.final_check_total_price_details{
    height: 20vh;
    overflow-y: scroll;
}
.final_check_total_price_details::-webkit-scrollbar{
    width: 5px;
}
.final_check_total_price_details::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background: skyblue;
}
.final_check_gst_price_box{
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
}
.final_check_gst_price{
    display: flex;
    justify-content: space-between;
}

.setup_regs_modal_heading{
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    word-spacing: 2px;
}
.registration_form_content label span{
    color: red;
    font-size: 1.1rem;
    margin-left: .3rem;
}
.preferenceviewbox{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
}
.stepper_finalcheck_img{
    width: 300px;
    height: 200px;
}
.project_setup_btn_box_paybtn_box{
    padding-bottom: 1rem;
    padding-top: 1rem;
}
@media (max-width:750px){
    .final_check_container{
        flex-direction: column;
        gap: 2.5rem;
    }
    .project_setup_btn_box_paybtn_box{
        padding-bottom: 1rem;
        padding-top: 1rem;
        flex-direction: column;
        gap: 1rem;
        padding-left: 2rem;
    }
    
}
@media (max-width:500px){
    .final_check_container_right{
        padding: 0rem;
        border-top: 1px solid gray;
        padding-top: 1rem;
    }
    .project_setup_btn_box_paybtn_box{
        padding-bottom: 2rem;
        padding-top: 2rem;
        padding-left: 0rem;
    }
    .project_setup1_container_third{
        padding: 1rem;
    }
    .final_check_container{
        padding-left: 1rem;
    }
    .final_check_container_left h6{
        font-size: .9rem;
    }
    .final_check_container_left h5{
        font-size: 1.1rem;
    }
}
@media (max-width:420px){
    .stepper_finalcheck_img{
        width: 220px;
        height: 200px;
    }
}

  