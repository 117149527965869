.special_category_container{
    display: flex;
    box-shadow: 0px 0px 5px rgb(176, 174, 174);
    margin-bottom: .5rem;
}

.customer_reviews_sidebar{
    background-color: white;
}
.online_customer_reviews_box,.offline_customer_reviews_box{
  padding: 2rem;
}
.online_customer_reviews_box h2,.offline_customer_reviews_box h2{
   font-size: 1.2rem;
   font-weight: bold;
   margin-bottom: 1rem;
}
.online_customer_reviews,.offline_customer_reviews{
    margin-bottom: .7rem;
    display: flex;
    gap: 1rem;
}
.online_customer_reviews .fa-circle-check{
    color: green;
}
.online_product_container_main{
   padding: 2rem;
   height: 70vh;
   overflow-y: scroll;
}
.offline_product_container_main{
    padding: 3rem 2rem;
    padding-top: 2rem;
    height: 70vh;
    overflow-y: scroll;
}
.online_product_container_main::-webkit-scrollbar,.offline_product_container_main::-webkit-scrollbar {
    width: 5px;
    /* background-color: black; */
}

.online_product_container_main::-webkit-scrollbar-thumb,.offline_product_container_main::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: skyblue;
}
.online_product_container,.offline_product_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.special_category_lowerpart{
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 3rem 2rem;
}
.special_category_tellus{
    display: flex;
   justify-content: center;
   gap: .5rem;
}
.special_category_tellus p{
    font-size: 1.1rem;
    font-weight: bold;
}
.special_category_tellus_btn{
    color: #fff;
    background-color: #007DC1;
    border: 2px solid white;
}
.special_category_tellus button:hover{
    border: 2px solid #007DC1;
    color: #007DC1;
    background-color: white;
}

@media(max-width: 767px) {
    .special_category_container{
    flex-direction: column;
    }
    .customer_reviews_sidebar{
        height: auto;
        position: relative;
        top: 0rem;
    }
    .special_category_lowerpart{
        flex-direction: column;
    }
}