.dash_sidebar_container{
    /* height: 100vh; */
    position: sticky;
    top: 0;
    left: 0;
    border: 2px solid #eee;
}
.dash_side_logo{
    margin-top: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dash_side_logo img{
    height: 60px;
}
.dash_side_list_box{
    padding-top: 2rem;
   padding-left: 3rem;
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

.dash_side_list_box .fa-clipboard{
    color: gray;
    margin-right: .5rem;
}

.dash_side_list_head{
    color: gray;
    font-weight: bold;
    padding-top: 1rem;
    
}
.dash_side_list_box a{
    text-decoration: none;
    color: rgb(114, 113, 113);
}
.selected-menu{
    background-color: #007DC1;
    padding: 1rem;
    color: white;
}
.selected-menu .fa-clipboard{
    color: white;
}
.dash_sidebar_smallscrn{
    display: none;
}
@media (min-width: 0px) and (max-width: 870px) {
    .dash_sidebar_bigscrn{
        display: none;
    }
    .dash_sidebar_smallscrn{
        display: block;
    }
}