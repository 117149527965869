.blog_content_box {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
    align-items: flex-start;
    padding: 2rem;
    margin: 1rem auto;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    border-radius: 10px;
    background: #FEFEFE;
    line-height: 1.8rem;
    font-size: 1.1rem;
    text-align: justify;
}

.blogs_main_box_text {
    height: 265px;
}
.blog_casestudy_img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog_casestudy_text {
    font-size: 15px;
    font-weight: 400;
    line-height: 170%;
    padding-bottom: 15px;
    margin-top: 1rem;
}

.blog_casestudy_text img {
    width: 120px;
    height: 50px;
    object-fit: contain;
}

.blog_button_container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.indiv_blog_heading h1 {
    font-size: 2rem;
    font-weight: bold;
}

@media(min-width:1010px) and (max-width:1390px) {
    .blogs_main_box {
        padding: 2%;
    }
}

@media(max-width: 1010px) {
    .blogs_main_box {
        flex-direction: column;
        padding: 2% 10%;
    }
}
@media(max-width:767px) {
    .indiv_blog_heading h1 {
        font-size: 1.5rem;
    }
    .blog_content_box{
        width: 97%;
    }
    .blog_content_box p{
        font-size: 1rem;
    }
    .blog_content_box ul{
        padding-left: 0;
    }
    .blog_content_box a{
        font-size: .9rem;
    }
}
@media(max-width:450px) {
    .blogs_main_box_text {
        height: auto;
    }
    .indiv_blog_heading h1 {
        font-size: 1.2rem;
    }
}