.individual_solution_down_text {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 400px;
    align-items: center;
    justify-content: center;
    padding-left: 5rem;
    box-shadow: 0px 0px 5px rgb(178, 176, 176);
}

.individual_solution_down_carousel {
    height: 400px;
    padding: 2rem;
    box-shadow: 0px 0px 5px rgb(178, 176, 176);
}

.individual_product_box a {
    text-decoration: none;
    color: black;
}