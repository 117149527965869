.explore_market_banner{
   width: 100%;
    background: #007DC1;
    color: white;
    height: 300px;
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   padding-left: 5rem;
}
.explore_market_banner h1{
    font-size: 3rem;
}
.explore_market_banner p{
    font-size: 1.2rem;
}
.explore_market_category{
    display: flex;
   justify-content: flex-start;
   /* align-items: center; */
   gap: 1rem;
   flex-wrap: wrap;
   padding: 5rem;
   padding-top:2rem ;
}
.explore_market_category_box{
    width: 24%;
    margin-top: 3rem;
    /* box-shadow: 0px 0px 5px #d8d6d6; */
    box-shadow: 0px 0px 20px 2px #d8d6d6;
    border-radius: 10px;
    position: relative;
}
.explore_market_popular_products_shop{
    position: absolute;
    bottom: 0;
    right: 1rem;
}
.explore_market_category a{
text-decoration: none;
color: black;
}
.explore_market_category_img{
    width: 100%;
}
.explore_market_category_img img{
    width: 100%;
    height: 200px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.explore_market_category_text{
    width: 90%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: start;
    padding: 0rem 1rem;
    padding-bottom: 1rem;
    /* height: 150px;
    overflow: auto; */
}
.explore_market_category_text::-webkit-scrollbar{
    width: 5px;
}
.explore_market_category_text h5{
    font-weight: bold;
    font-size: 1rem;
}
.explore_market_category_text p{
    font-size: 14px;
    /* height: 90px;
    overflow: hidden; */
}
.explore_market_category_down{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}
.explore_market_category_down_img img{
    width: 100%;
    height: 400px;
    /* object-fit: cover; */
}
.explore_market_category_down_img{
    padding-left: 1rem;
    box-shadow: 0px 0px 5px rgb(178, 176, 176);
}
.explore_market_category_down_text{
    display: flex;
    flex-direction: column;
    padding:2rem;
    height: 400px;
    align-items: flex-start;
    padding-left: 5rem;
   box-shadow: 0px 0px 5px rgb(178, 176, 176);
}
.explore_market_category_down_text h5{
    margin: 1.5rem 0;
    font-size: 1rem;
    font-weight: 600;
}
.explore_market_category_down_text_p{
    margin: 2rem 0rem;
}
.explore_market_category_down_text_smallscrn{
    height: 390px;
}
.explore_carousel_box{
    height: 400px;
}
@media(max-width: 1290px) {
    .explore_market_category_down_text_smallscrn_p{
        margin-bottom: 0rem;
    }
}
@media(max-width: 1050px) {
    .explore_market_banner{
        padding: 1rem;
    }
    .explore_market_category{
        flex-wrap: wrap;
    }
     .explore_market_category_down_text_smallscrn{
       padding-left: 2rem;
    }
}
@media(max-width: 750px) {
    .explore_market_category{
       padding: 1.5rem;
       flex-direction: column;
       gap: 0rem;
    }
    .explore_market_category_box{
        width: 100%;
    }
    .explore_market_category_img img{
        width: 100%;
        border-radius: 10px;
        height: auto;
    }
    .explore_market_category_text{
        width: 100%;
    }
    .explore_market_banner h1{
        font-size: 2rem;
    }
    .explore_market_banner p{
        font-size: 1rem;
    }
}
@media(max-width: 910px) {
    /* .explore_market_category_down{
        flex-direction: column;
        gap: 2rem;
    } */
    .explore_market_category_down_smallscrn{
        flex-direction: column;
        gap: 2rem;
    }
    .explore_market_category_down_text_smallscrn,.explore_market_category_down_carousel_smallscrn{
        width: 100%;
    }
    .explore_carousel_box{
        height: auto;
    }
    .explore_market_category_down_text_p{
        margin: 0rem;
        margin-top: 1rem;
    }
    .explore_market_category_down_text{
        padding-left: 2rem;
        padding-bottom: 0rem;
        /* overflow: auto; */
    }
}
@media(max-width: 767px) {
    .explore_market_category_down{
        flex-direction: column;
        gap: 2rem;
    }
    .explore_market_category_down_img {
        height: auto;
        width: 100%;
        padding-left: 0rem;
    }
    .explore_market_category_down_text{
        padding-left: 2rem;
        height: auto;
    }
}