.login_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.login_logo img {
    width: 250px;
}

.login_logo h6 {
    font-size: 1.2rem;
    color: #adadad;
}

.login-area {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box {
    width: 400px;
}

.login-title h3 {
    color: #444444;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;
}

@media(max-width: 575px) {
    .login-title h3 {
        font-size: 20px;
    }

}



.login_form_control {
    padding: 0;
    width: auto;
    height: auto;
    background: transparent;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;

}

.login_form_control label {
    font-size: 15px;
    color: #555555;
    font-weight: 500;
    margin-bottom: .5rem;
}
.login_form_control span {
    color: red;
    font-size: 1.1rem;
    margin-left: 0.3rem;
}
@media(max-width: 575px) {
    .login_form_control label {
        font-size: 14px;
    }
}

.login_input {
    width: 100%;
    height: 48px;
    background-color: #ffffff;
    font-size: 14px;
    padding: 15px 20px;
    color: black;
    outline: none;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.login_input::placeholder {
    font-size: 14px;
    color: #969696;
}

@media(max-width: 575px) {
    .login_input::placeholder {
        font-size: 13px;
    }
}

.login_input:focus {
    border-color: rgb(77, 215, 77);
}

@media(max-width: 575px) {
    .login_input:focus {
        height: 40px;
    }
}

.login_form_control .success_border:focus {
    border-color: 1px solid #dddddd;
}

.login_form_control .error_border {
    border-color: #ff6f6f;
}

.login_form_control .error_message {
    color: #ff6f6f;
    font-size: .9rem;
}

.login_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginButton {
    font-size: 16px;
    color: #fff;
    background: #007DC1;
    width: 30%;
    height: 48px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
}

.loginButton:hover {
    background: #2699d7;
}

@media(max-width: 575px) {
    .loginButton {
        font-size: 15px;
        height: 40px;
    }

}

.save-passowrd label {
    font-size: 14px;
    display: block;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;
}

.save-passowrd input[type=checkbox] {
    border: 2px solid #dddddd;
    appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-right: 6px;
    position: relative;
    top: 4px;
}

.save-passowrd input[type=checkbox]:focus {
    outline: none;
}

.save-passowrd input[type=checkbox]:checked {
    background-color: green;
    background: green url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
    border-color: green;

}

.save-forget-password,.save-forget-password-auth {
    justify-content: center;
    gap: 2rem;
}

.forget-password a, .forget-password-auth {
    background: transparent;
    border: none;
    font-size: 14px;
    color: green;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.forget-password a:hover, .forget-password-auth:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}

.not_account-btn a {
    font-size: 14px;
    color: green;
    font-weight: 500;
    text-decoration: none;
}

.not_account-btn p a:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}