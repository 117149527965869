.audit_count_numberbox::-webkit-inner-spin-button,
.audit_count_numberbox::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
.project_setup_storeadd{
    flex-wrap: wrap;
    margin-bottom: 2rem;
    align-items: center;
}
.bulk_upload_btn{
    border: 2px solid #007DC1;
    background-color: white;
    color: #007DC1;
    margin-right: 1rem;
}
.bulk_upload_btn:hover{
    background-color: #007DC1;
    color: white;
}
.store_details_table_head{
  box-shadow: 0px 0px 5px rgb(141, 140, 140);
  font-size: .9rem;
  border-radius: 10px;
}
.project_setup_btn_box_left{
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    align-items: center;
}
.loading-cell{
    column-span : 9;
   }
@media (max-width:500px){
    .project_setup_btn_box_left{
        flex-direction: column;
        align-items: flex-start;
    }
}
@media (max-width: 1250px) {
     .bulk_upload_btn,.upload_btn {
         margin-bottom: 1rem; 
     }
    }
@media (max-width: 750px) {
    
   .store_uploadbtn_box, .project_setup_storeadd{
    display: flex;
    flex-direction: column;
    gap: .3rem;
   }
   .project_setup_storeadd{
    gap: 1rem;
    margin-top: 2rem;
   }
    .bulk_upload_btn,
    .upload_btn {
        width: 100%; 
        margin-right: 0; 
    }
    .registration_form_content {
        margin-bottom: 1rem; 
    }
   .loading-cell{
    column-span : 4;
   }
}