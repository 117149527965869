.project_setup_final_container {
    width: 70%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 2rem;
    margin: 0 auto;
    margin-bottom: 3rem;
    border-radius: 10px;
    background: #FEFEFE;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.25);
}

.project_setup_final_container h2 {
    font-weight: bold;
    font-size: 1.7rem;
}

.project_setup_final_container p {
    font-weight: bold;
}

@media (max-width:750px){
    .project_setup_final_container{
        width: 90%;
    }
}
