.project_setup1_main_container {
    /* box-shadow: 0px 5px 7px rgb(200, 199, 199); */
}

.project_setup1_container {
    width: 90%;
    padding: 2rem;
    padding-top: 1rem;
    margin: 0 auto;
}

.project_setup1_content,.project_setup1_content_special {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    margin-top: 1rem;
}

.project_setup1_content input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 15px 20px;
    color: black;
    outline: none;
    border: 1px solid #dddddd;
    background: #F3F3F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

.project_setup1_content input:focus {
    border-color: rgb(77, 215, 77);
}

.project_setup1_content label {
    font-size: 1rem;
}
.project_setup1_content span,.project_setup1_content_special span {
    color: red;
    font-size: 1.1rem;
    margin-left: 0.3rem;
}
.project_setup1_industry {
    background: #F3F3F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

.savefr_ltr_btn_box {
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    padding-bottom: 0;
}

.audience_preference {
    margin-top: 2rem;
}

.project_setup_btn_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    padding-top: 1rem;
    margin: 0 auto;
}
.project_setup_btn_box_secondpage{
    padding-bottom: 1rem;
}
.project_setup_btn_box_table{
    width: 40%;
    /* width: 30%; */
    margin-left: 7%;
    border: 1px solid #dee2e6;
}
.project_setup_btn_box span {
    margin-right: 1rem;
}
.project_setup1_content textarea {
    outline: none;
    border: none;
    background: #F3F3F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

.project_setup1_content textarea:focus {
    border-color: rgb(77, 215, 77);
}

.selected_files {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
}

.selected_files_name {
    border: 1px solid rgb(132, 130, 130);
    padding: .3rem;
}

.selected_files_plus_btn {
    background: transparent;
    border: none;
    outline: none;
}
.nxtbtn_smlscrn_box,.nxtbtn_smlscrn_box_setup2{
    display: none;
}
.audience_preference_down{
    margin-bottom: 1rem;
    margin-top: 1rem;
}
@media (max-width:1100px){
    .project_setup_btn_box_table{
        width: 70%;
    }
}

@media (max-width:750px){
    .project_setup_btn_box{
        width: 97%;
        margin-bottom: 2rem;
        /* margin-left: 1rem; */
        flex-direction: column;
        align-items: flex-start;
    }
    .project_setup_btn_box_table{
        width: 90%;
        font-size: .8rem;
    }
    .project_setup1_container {
        width: 100%;
    }
    .nxtbtn_smlscrn_box{
        display: flex;
        justify-content: flex-end;
        width: 90%;
    }
    .nxtbtn_smlscrn_box_setup2{
        display: flex;
        justify-content: space-between;
        width: 90%;
    }
    .project_setup_btn_box_left{
        width: 90%;
    }
    .nxtbtn_box{
        display: none;
    }
    .audience_preference_down{
        margin-top: 0rem;
    }
}

@media (max-width:500px){
    .project_setup_btn_box{
        width: 100%;
        margin-bottom: 2rem;
        margin-left: 1rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .radio_preference{
        display: flex;
        width: 100%;
    }
    .audience_preference_smlscrn{
        margin-top: 1rem;
    }
    .project_setup1_container {
        width: 100%;
    }
    }