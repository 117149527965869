.home-banner-area {
    background: url("/src/images/HomeBanner.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    position: relative;
}

.home-banner-area::before {
    position: absolute;
    content: '';
    /* background: rgb(67, 95, 139); */
    opacity: 0.6;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.home-banner-content-box {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
}

.home-banner-content-box .home-banner-content {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10%;
}

.hero-box {
    text-align: start;
}

.home-banner-content-box .home-banner-content .hero-box h1 {
    font-size: 70px;
    color: #ffffff;
    /* max-width: 700px; */
    margin: auto;
    margin-bottom: 20px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .hero-box h1 {
        font-size: 26px;
        font-weight: 500;
        max-width: 100%;
    }

    .home-banner-content-box .home-banner-content .hero-box h1 {
        font-size: 30px;
    }

}

.hero-box p {
    font-size: 1.1rem;
    color: #ffffff;
    max-width: 1000px;
    line-height: 30px;
    margin-bottom: 20px;
}

@media (max-width: 575px) {
    .hero-box p {
        font-size: 15px;
        line-height: 25px;
        max-width: 600px;
    }
}

.banner-btn-container {
    display: flex;
    gap: .5rem;
}

.banner-btn a,
.banner-btn button {
    font-size: 14px;
    color: #fff;
    background-color: #009444;
    display: inline-block;
    /* height: auto !important; */
    text-transform: uppercase;
    font-weight: 500;
    word-spacing: 1px;
    letter-spacing: 1px;
    padding: 10px 50px;
    border-radius: 5px;
    margin-top: 20px;
}

.banner-btn a:hover {
    background-color: #13ac36;
}

.banner-btn button {
    background-color: #ffffff;
    color: black;
}

/* @media (max-width: 510px) {

    .banner-btn button,
    .banner-btn a {
        padding: 10px 30px;
        word-spacing: normal;
        letter-spacing: normal;
        font-size: 12px;
    }
} */
@media (max-width: 750px) {

    .banner-btn-container {
        flex-direction: column;
    }
}

.video-player .play-button {

    transform: translateX(0) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    margin: auto;
    border-radius: 50%;
}

.video-player .play-button i {
    position: relative;
    font-size: 40px;
    color: #ffffff;
    z-index: 11;
    padding-top: 2px;
    margin-left: -2px;
}

.video-player::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    /* background: ${colors.green}; */
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-player::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    /* background: ${colors.green}; */
    border-radius: 50%;
    transition: all 200ms;
}

.video-player .play-button i:hover {

    color: #ffffff;

}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}






/* @media (max-width: 767px) {
        height: 450px;
    }

    @media (max-width: 575px) {
        height: 360px;
    } */