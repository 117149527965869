.savefr_later_container {
   height: 570px;
   width: 100%;
   padding: 1rem;
   border: nne;

}

@media(max-width: 960px) {
   .savefr_later_container {
      overflow-y: scroll;
   }
}

.login_tab {
   margin: 7rem auto;
}