.categoryname_banner{
    width: 100%;
    background: #007DC1;
    color: white;
    height: 200px;
    display: flex;
   justify-content: flex-start;
   align-items: center;
   padding-left: 5rem;
}
.categoryname_banner h1{
    font-size: 3.5rem;
}
.category_product_container{
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.category_product_container h5{
    font-size: 1.1rem;
    line-height: 170%;
}
.category_product_box_container{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.category_product_box{
    box-shadow: 0px 0px 20px 2px #d8d6d6;
    /* padding-bottom: 1.5rem; */
    padding-bottom: 1rem;
    padding-top: 2.5rem;
    border-radius: 10px;
    /* overflow: hidden; */
    position: relative;
    /* background-color: red; */
}
.product_page_category{
 width: 24%;
}
.category_product_box:hover{
    transform: scale(1.01);
}
.category_product_box_img{
    height: 200px;
    border-radius: 10px;
    position: relative;
}
.category_product_box_img img{
    width: 100%;
    height: 200px;
}
.category_product_box .category_name{
    position: absolute;
    background-color: white;
    top: 0rem;
    left: .5rem;
    color: black;
    font-size: 15px;
    padding: .5rem;
    border-radius: 10px;
}
.category_product_box_img .categoty_price{
    position: absolute;
    background-color: white;
    bottom: 1rem;
    right: 0rem;
    color: black;
    font-size: 14px;
    font-weight: bold;
    padding: .5rem;
    border-radius: 10px;
}
.category_product_box_text{
    /* height: 120px; */
    /* overflow: hidden; */
    margin-top: 1.5rem;
    margin-bottom: 1rem;
   text-align: start;
   padding:0rem 1rem;
   /* background-color: yellow; */
}
.category_product_box_text::-webkit-scrollbar{
    width: 5px;
}
.category_product_box_text h5{
    font-size: 1rem;
    font-weight: bold;
}
.category_product_box_text p{
    font-size: 14px;
}
.category_product_btn_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    text-decoration: none;
    color: #007DC1;
    /* background-color: green; */
}
.category_product_btn_container span{
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
}
.category_product_btn_container span:hover{
    text-decoration: underline;
    text-underline-offset: 10px;
}
.category_brand_heading{
    text-align: center;
    margin-top: 5rem;
   
}
.category_brand{
    padding: 2% 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.category_brand_box{
    box-shadow: 0px 0px 5px rgb(219, 215, 215);
    padding: 0;
}
.category_brand_img img{
    width: 100%;
    height: 200px;
}

.category_brand_text{
    padding: 1rem;
    height: 200px;
}
@media(max-width: 1200px){
    .product_page_category{
        width: 30%;
       }
}
@media(max-width: 950px){
    .product_page_category{
        width: 45%;
       }
       .category_product_box_container{
        justify-content: center;
        gap: 2rem;
       }
}
@media(max-width: 767px) {
    .category_product_box_img, .category_product_box_img img,.product_small_img img{
        height: auto;
    }
    .category_brand {
        flex-direction: column;
    }
    .category_brand_heading{
        margin-top: 3rem;
        margin-bottom: 1rem;
       
    }
    .category_product_box_container{
       flex-direction: column;
    }
    .product_page_category{
        width: 100%;
       }
       .category_product_container{
        padding: 3rem 2rem;
       }
       .categoryname_banner{
        padding-left: 2rem;
       }
}