.footer1 {
    padding: 70px 0 80px;
    background: #173358;
    color: white;
    padding: 70px 10%;
}
.footer1 li{
    margin-top: .5rem;
    color: rgb(216, 213, 213);
    font-size: .9rem;
}
.footer1 li a {
    text-decoration: none;
    color: rgb(216, 213, 213);
    font-size: .9rem;
}

.footer1 li a:hover {
    color: #35bdd8;
}
.f-links h5 {
    color: #fff;
    margin-top: 8px;
    margin-bottom: 18px;
    font-size: 1.1rem;
    letter-spacing: 1px;
}

@media (max-width: 575px) {
    .f-links h5 {
        margin-bottom: 15px;
        font-size: 20px;
    }

}

@media (max-width: 767px) {
    .f-links {
        margin-bottom: 20px;
        display: inline-block;
    }

}

@media (max-width: 450px) {
    .footer_container {
        flex-direction: column;
    }
}