.back_market {
    padding: 2rem 3rem;
    padding-bottom: 0rem;
}
.back_market_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.back_market_flex_country{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.back_market_flex_country_dropdown{
    display: flex;
    justify-content: start;
    align-items: 10px;
}
.back_market a,.back_market_a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    cursor: pointer;
}

.back_market i {
    font-size: 1.5rem;
}

.back_market a span:hover,.back_market_a span:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}

.product_detail_part {
    padding: 2rem 5rem;
    padding-left: 3rem;
    display: flex;
}

.product_detail_leftpart,
.product_detail_rightpart {
    box-shadow: 0px 0px 5px rgb(177, 176, 176);
    padding: 1.5rem;
    border-radius: 10px;
}
.product_detail_leftpart{
    height: 75vh;
    /* height: auto; */
}
.product_detail_rightpart {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.product_detail_carousel img {
    border-radius: 10px;
}

.product_detail_carousel .carousel_product_img {
    height: 400px;
    cursor: url(//www.molofoods.com/cdn/shop/t/9/assets/icon-zoom-in.svg?v=6646344…),zoom-in;
    /* cursor: zoom-in; */
}

.product_small_img_container {
    display: flex;
    margin-top: 1rem;
    padding: 1.5rem;
    overflow-y: auto;
}
.product_small_img{
    margin-top: 2rem;
}

.product_small_img img {
    width: 95%;
    height: 90px;
    /* object-fit: cover; */
    border-radius: 10px;
    cursor: pointer;
}
.full-screen-image{
    right: 100%;
    transition: 850ms;
    width: 70%;
}
.full-screen-image_active{
    right: 0;
    /* border:5px solid yellow; */
    transition: 350ms;
    width: 70%;
}
.product_response_box {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
}

.quantityContainer {
    border: 1px solid green;
    border-radius: 5px;
    width: 6vw;
    height: 2rem;
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantityContainer button {
    border: none;
    outline: none;
    background: transparent;
    font-size: 1rem;
}
.quantity-input{
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
}
.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.product_detail_border {
    border-left: 2px solid black;
}
.product_detail_border1{
padding-right: 2rem;
}
.product_detail_rightpart_lower h5 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 0rem 2rem;
}

.product_detail_point {
    display: flex;
    gap: .7rem;
}
.product_detail_point .fa-circle-check {
    color: green;
    font-size: 15px;
}
.product_detail_point p{
    font-size: 15px;
}
.product_page_bottom {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    padding-top: 0rem;
}

.product_page_bottom_left {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.product_page_bottom_right {
    padding-bottom: 3rem;
}

.linked_button_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
}

.linked_button_container button {
    margin-left: 2rem;
}

.product_page_bottom_right_text {
    box-shadow: 0px 0px 5px rgb(177, 176, 176);
    padding: 2rem;
    padding-bottom: 0;
}

.category_product_btn_container a {
    text-decoration: none;
    color: #007DC1;
}

.category_product_btn_container a span {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
}

.category_product_btn_container a span:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}
.suggestion_box{
    width: 33%;
}
.howitwrks_tellus_productpage_p_mobile{
    display: none;
    font-size: 1rem;
}
.product_detail_rightpart_lower_box{
    display: flex;
}
.product_detail_rightpart_lower_box_smallscrn{
    display: none;
}
.registration_tab{
    padding: 5rem 3rem;
}
@media(max-width: 1440px){
    .howitwrks_tellus_productpage{
        padding-left: 5%;
    }
    .product_detail_leftpart{
        height: auto;
    }
}
@media(max-width: 1300px) {
    .howitwrks_tellus_productpage{
        flex-direction: column;
        gap: 1rem;
    }
    .howitwrks_tellus_productpage_arrow{
        display: none;
    }
}
/* @media(max-width: 1250px){
    .product_detail_leftpart{
        height: 85vh;
    }
} */
@media(max-width: 1200px) {
    .product_detail_part {
        flex-direction: column;
        padding: 2rem;
    }
    .product_detail_carousel .carousel_product_img,.product_small_img img  {
        height: auto;
    }
    .product_detail_leftpart,
    .product_detail_rightpart {
        width: 100%;
    }
    .product_detail_leftpart{
        height: auto;
    }
    .product_detail_rightpart {
        margin-left: 0rem;
        margin-top: 2rem;
    }
}

@media(max-width: 950px) {
    .product_page_bottom{
        flex-direction: column;
    }
    .product_page_bottom_left{
        flex-direction: column;
    }
    .quantityContainer{
        width: 10vw;
    }
   
    .suggestion_box{
        width: 90%;
    }
    .howitwrks_tellus_productpage button{
        font-size: .9rem;
        padding: 1rem;
    }
    .tab_phone select{
      padding: 0px;
    }
    /* .product_detail_leftpart{
        height: auto;
    } */
}
@media(max-width: 750px) {
    .dashquantityContainer,.quantityContainer{
        width: 15vw;
    }

    .back_market{
        padding-left: 2rem;
    }
    .howitwrks_tellus_productpage_p{
        font-size: 1rem;
    }
    .full-screen-image,.full-screen-image_active{
        width: 85%;
    }
}
@media (max-width: 600px){
    .howitwrks_tellus_productpage{
        padding-left: 0%;
    } 
    .howitwrks_tellus_productpage_p{
        display: none;
    }
    .howitwrks_tellus_productpage_p_mobile{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0rem;
    }
    .howitwrks_tellus_productpage{
        gap: 0rem;
        margin-top: 2rem;
    }
    .product_response_box{
        flex-direction: column;
        align-items: flex-start;
    }
    .quantityContainer{
        width: 20vw;
    }
    .product_detail_rightpart_lower_box{
        flex-direction: column;
        display: none;
        }
        .product_detail_rightpart_lower_box_smallscrn{
            display: block;
        }
        .product_detail_rightpart_lower h5,.product_detail_border_ul {
            padding: 0rem;
        }
        .product_detail_border {
            border-left: none;
            border-top: 2px solid black;
            padding: 2rem 0rem;
        }
        .product_detail_border1{
            padding-bottom: 2rem;
        }
        .product_overview_mobile{
            margin-top: .8rem;
        }
        .back_market_flex{
            flex-direction: column;
            justify-content:flex-start ;
            align-items: flex-start;
        }
}
@media(max-width: 450px) {
    .product_response_box{
        flex-direction: column;
        align-items: flex-start;
    }
    .quantityContainer{
        width: 20vw;
    }
    .dashquantityContainer{
        width: 30vw;
    }
    .howitwrks_tellus_productpage_p_mobile_p{
        font-size: .9rem;
    }
    .registration_tab{
        padding: 5rem 1rem;
    }
}



/* @media(max-width: 500px) {
    .product_detail_rightpart_lower_box{
    flex-direction: column;
    display: none;
    }
    .product_detail_rightpart_lower_box_smallscrn{
        display: block;
    }
    .product_detail_rightpart_lower h5,.product_detail_border_ul {
        padding: 0rem;
    }
    .product_detail_border {
        border-left: none;
        border-top: 2px solid black;
        padding: 2rem 0rem;
    }
    .product_detail_border1{
        padding-bottom: 2rem;
    }
} */

/* --------------------------------------------------------------------------------------- */

