.profile_pic_box{
    width: 40%;
    border: 1px solid black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}
.profile_pic img{
width: 150px;
}
.profile_detail_box{
    margin-top: 7rem;
    padding: 0rem 2rem;
}
.otp_box_container{
    display: flex;
    justify-content: center;
    gap: .5rem;
    margin-top: 1.9rem;
}
.otp_box{
    width: 12%;
    height: 2.5rem;
    padding: .5rem;
    border-radius: 5px;
    border: 1px solid #8c8a8a;
    outline-color: #f84464;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

@media(max-width:500px){
    .profile_detail_box{
        padding: 1rem;
        margin-top: 2rem;
    }
}