.registration_container {
    padding: 3rem;
    padding-top: 5rem;
    padding-bottom: 0rem;
}

.registration_left_part {
    border-right: 2px solid black;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 2rem;
    align-items: center;
}

.registration_left_part_upper {
    width: 100%;
    margin-bottom: 1rem;
}

.registration_left_part h5 {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 1.1rem;
}

.registration_left_part iframe {
    width: 80%;
    height: 300px;
    margin-left: 10%;
}

.registration_logo_box_container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    flex-wrap: wrap;
}

.registration_logo_box img {
    width: 100px;
}

.registration_logo_box p {
    font-size: .9rem;
}

.registration_form_content {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.registration_form_content input {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    padding: 15px 20px;
    color: black;
    outline: none;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.registration_form_content select {
    /* width: 100%; */
    height: 40px;
    background-color: #ffffff;
    font-size: 14px;
    padding: 0px 20px;
    color: black;
    outline: none;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.registration_form_content input:focus {
    border-color: rgb(77, 215, 77);
}
.registration_form_content select:focus {
    border-color: rgb(77, 215, 77);
}

.registration_form_content .success_border:focus {
    border-color: 1px solid #dddddd;
}

.registration_form_content .error_border {
    border-color: #ff6f6f;
}

.registration_form_content .error_message {
    color: #ff6f6f;
    font-size: .9rem;
}

.registration_form_content label {
    font-size: 1rem;
}

.registration_right_part {
    padding-left: 2rem;
}

.registration_right_part h5 {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.registration_right_part p {
    font-size: 1rem;
    margin-bottom: 2rem;
}

.registration_form_terms h5 {
    font-size: .8rem;
    margin: .8rem 0rem;
}

.create_ac_btn {
    background: #007DC1;
    color: white;
    border: 2px solid #007DC1;
}

.create_ac_btn:hover {
    background: #2699d7;
    color: white;
}
.smallscrn_mobilenumber{
    display: none;
}
@media(max-width:1100px){
    .registration_form_content select{
        padding: 0px;
    }
}
@media(max-width: 770px) {
    .registration_container{
        padding: 2rem;
        padding-top: 5rem;
        padding-bottom: 0rem;
        padding-right: 1rem;
    }
    .registration_left_part {
        border-right: 2px solid white;
        border-bottom: 2px solid black;
        margin-bottom: 3rem;
        padding-bottom: 1rem;
        padding-left: 0;
    }

    .registration_left_part iframe {
        width: 100%;
        margin-left: 0;
    }
    .registration_right_part{
        padding-left: 0rem;
    }
    .registration_logo_box_container{
        width: 100%;
    }
    .registration_logo_box p{
        font-size: .7rem;
        margin-top: 5px;
    }
    .registration_logo_box img{
        width: 70px;
    }
    .bigscrn_mobilenumber{
        display: none;
    }
    .smallscrn_mobilenumber{
        display: block;
    }
}